<template>
    <div>
      <b-card no-body class="mb-0">
        <b-form @submit.prevent="handleAddRow($event)" ref="myForm">
          <validation-observer ref="simpleRules">
            <!-- <div
              v-if="isSideDataLoading"
              style="height: 400px"
              class="d-flex justify-content-center align-items-center"
            >
              <b-spinner label="Loading..."></b-spinner>
            </div> -->
            <div class="m-2" >
              <b-row class="d-flex justify-content-between w-100">
                <b-col md="12">
                  <b-form-group>
                    <label for="input" class="font-weight-bold">Title</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Title"
                    >
                      <b-form-input
                        v-model="title"
                        placeholder="Title"
                        maxlength="50"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label class="font-weight-bold">Audio</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Audio"
                    >
                      <b-form-file
                        @input="uploadDoc($event, 'audio', 'audio')"
                        v-model="audio"
                        label="audio"
                        class="d-flex"
                        placeholder="Drop Audio"
                      >
                      </b-form-file>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for="input" class="font-weight-bold">Thusbnail</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Thumbnail"
                    >
                      <b-form-file
                        id="file-default"
                        @input="uploadDoc($event, 'image', 'thumbnail')"
                        v-model="image"
                        label="left"
                        class="d-flex"
                        placeholder="Drop Image"
                      >
                      </b-form-file>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group>                        
                        <input
                        style="height: 20px; width:20px"
                        class="mr-2 mt-1"
                        type="checkbox"
                        v-model="status"
                        placeholder="Keywords"
                        />
                      <label for="input" class="font-weight-bold" style="font-size:25px" >Set as default</label>
                    </b-form-group>
                  </b-col>
                <b-col md="12" class="d-flex justify-content-start mt-2">
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="handleAddRow(selectedId)"
                    :disabled="flag"
                  >
                    {{ ifEdit == true ? "Update" : "Save" }}
                  </b-button>
                  <b-button type="reset" variant="primary" @click="Close">
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </template>
    <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BFormFile,
    BButton,
    BForm,
    BSpinner,
    BFormCheckbox
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import axios from "@/component/axios";
  import { quillEditor } from "vue-quill-editor";
  import { required } from "@validations";

  export default {
    components: {
      BCard,
      BFormCheckbox,
      BSpinner,
      BFormFile,
      ValidationProvider,
      ValidationObserver,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BForm,
      quillEditor,
    },
    data() {
      return {
        snowOption: {
          theme: "snow",
        },
        content: "",
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        data: [],
        title: "",
        pagelink: "",
        isSideDataLoading: false,
        status:"",
        audio:"",
        image: "",
        metaDesc: "",
        flag: false,
        ifEdit: false,
        id:null
      };
    },
    mounted() {
      // this.$nextTick(() => {
      //   this.$refs.editor.quill.root.style.height = '100%';
      // });
      this.ifEdit = !!this.$route.params.id;
      this.ifEdit && this.getEditValue();
    },
    methods: {
      async getEditValue() {
        await axios({
          method: "GET",
          url: `${this.baseApi}audio/${this.$route.params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
          .then((json) => {
            this.getEditItems(json.data);
          })
          .catch((error) => console.log(error, "error"));
      },
      getEditItems(item) {
        this.title=item.title;
        this.audio=item.url;
        this.image=item.thumbnail;
        this.status=item.status;
      },
      // convertToSlug(Text) {
      //   return Text.toLowerCase()
      //     .replace(/ /g, "-")
      //     .replace(/[^\w-]+/g, "");
      // },
      async created() {
        const id = this.$route.params.id;
        await axios({
          method: "GET",
          url: `${this.baseApi}audio/${id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
          .then((response) => {
            let data = response?.data.data;
            // console.log(data,"data here")
            this.image = data.image;
            this.id = data.id;
          })
          .catch((error) => {
            console.error("Error fetching CMS data:", error);
          });
      },
      handleAddRow() {
      this.$refs.simpleRules.validate().then((success) => {
      if (!success) {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Please fill in all fields",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
  
      const customerData = {
        title: this.title,
        url: this.audio,
        status: this.status ? 1 : 0,
        thumbnail:this.image
      };
  
      const url = this.ifEdit ? `${this.baseApi}audio/${this.$route.params.id}` : `${this.baseApi}audio`;
      const method = this.ifEdit ? "put" : "post";
  
      axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(customerData),
      })
      .then((response) => {
        this.$swal({
          title: "Submitted",
          text: this.ifEdit ? "Audio Updated Successfully" : "Audio Created Successfully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.$forceUpdate();
        this.$router.push('/music')
      })
      .catch((error) => {
        this.$swal({
          title: "Error!",
          text: `${error}`,
          icon: "error",
          timer: 5000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    });
  },
      
      async uploadDoc(event, variable, name) {
        this.flag = true;
        const formData = new FormData();
        formData.append("url", event);
        formData.append("type", "image");
        await axios({
          method: "POST",
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          //   console.log(response.data.data.url)
          //   let data = {
          //     url: response.data.data.url,
          //     admin_id: JSON.parse(localStorage.getItem('userData')).id,
          //     status: 1
          //   }
          this[variable] = response.data.data.url;
          this.flag = false;
        });
      },
      Close() {
        this.$router.push({ path: "/music" });
        // this.Addsupple = false;
        this.openModel = false;
      },
      
    },
  };
  </script>
    <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  </style>
    
    <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
    
    